<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/PageHeader";
import appConfig from "../../../app.config";

import axios from 'axios';
import EpisodeRowItem from "../episodes/EpisodeRowItem.vue";

export default {
  page: {
    title: "Episode by tag",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      data: {
        tag: "",
        list: [],
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    EpisodeRowItem
  },
  created: function () {
    console.log('Tag', this.$route.params.value);
    axios.get('/api/episodes/tag/' + this.$route.params.value)
      .then((response) => {
        this.data = response.data;
      })
      .catch(function () {
      });
  },
  computed: {
    getTagFromUrl() {
      return this.$route.params.value;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('t-episodes-bytag-title') + getTagFromUrl" :description="$t('t-episodes-bytag-description')">
      <button type="button" class="btn btn-primary" v-if="false">
        <i class="ri-add-line align-middle me-1"></i> Create Episode
      </button>
    </PageHeader>

    <div class="team-list list-view-filter row">
      <div v-for="p in data.list" v-bind:key="p">
        <EpisodeRowItem v-bind:data="p"></EpisodeRowItem>
      </div>
    </div>

  </Layout>
</template>